export default {
EVENTS_LIST: "Events list",
ADD_EVENTS: "Add an Event",
EVENTS_ADDED: "Event Added",
OWNER: "Owner",
EDIT_EVENTS: "Edit an Event",
EVENTS_UPDATED: "Event Updated",
DELETE_THIS_EVENTS: "Delete this Event?",
EVENTS_DELETED: "Event Deleted",
VIEW_EVENTS: "Event",
}